import React from "react";
import Link from "next/link";
import Image from "next/image";
import classnames from "classnames";
import { parseCookies } from "nookies";
import { useStoreState } from "pullstate";

import Typography from "shared/components/Typography";
import { applicationRoutes } from "shared/helpers/applicationRouting";
import { PodcastTileInterface } from "shared/helpers/pageHelpers/Podcast/interface";
import RecommendedEpisodePlayer from "shared/components/Players/EpisodePlayer/RecommendedEpisodePlayer";
import { getImgixURL } from "@/helpers/getImgixURL";
import { PlayerStore } from "@/store/PlayerStore";

import styles from "./RecommendedPodcastTile.module.scss";

export const RecommendedPodcastTile: React.FC<PodcastTileInterface> = ({
  id,
  slug,
  title,
  publishedAt,
  podcast,
  bookmarked,
  media: { duration, longVersionDuration, subtitle, url, slug: mediaSlug },
  isDark,
  className,
  images,
  hosts,
  tags,
  index,
  time,
}) => {
  const { listenedEpisodes } = useStoreState(PlayerStore, (store) => store);
  const fullTitle = `${podcast.title} - ${subtitle}`;
  const podcastSlug = podcast.slug;
  const mediaMetaData = {
    title: title,
    artwork: images.feed.lgx2,
    subTitle: podcast.title,
    slug: mediaSlug,
    podcast: { slug: podcastSlug },
    tags: !!tags?.length && tags.map(({ title }) => title),
    authors: !!hosts?.length && hosts.map(({ name }) => name),
    publishedAt,
  };
  const cookies = parseCookies();
  const apiToken = cookies?.token;
  const audioURL = apiToken ? `${url}?api_token=${apiToken}` : url;
  const image = images.recommended;
  const img = getImgixURL(image.lgx2);
  const episodeTime = listenedEpisodes?.[slug]?.status || time;
  const portal = process.env.NEXT_PUBLIC_PORTAL;

  return (
    <div
      className={classnames(
        styles.podcastTile,
        isDark && styles.dark,
        className
      )}
      key={id}
    >
      <Link
        href={`${applicationRoutes.PODCAST_EPISODE}[slug]`}
        as={applicationRoutes.PODCAST_EPISODE + slug}
      >
        <a className={styles.image}>
          <Image
            layout="fill"
            objectFit="cover"
            objectPosition="center center"
            src={img}
            placeholder="blur"
            blurDataURL={`https://newonce.imgix.net/${img}?fm=blurhash`}
            alt={fullTitle}
            priority={index < 2}
          />
        </a>
      </Link>
      <div className={styles.meta}>
        <Link
          href={`${applicationRoutes.PODCAST}[slug]`}
          as={applicationRoutes.PODCAST + podcastSlug}
        >
          <a className={classnames(styles.podcast, styles[portal])}>
            <Typography component="span" variant="small">
              {podcast.title}
            </Typography>
          </a>
        </Link>
        <Link
          href={`${applicationRoutes.PODCAST_EPISODE}[slug]`}
          as={applicationRoutes.PODCAST_EPISODE + slug}
        >
          <a className={styles.episodeLink}>
            <Typography component="h3" variant="h5" className={styles.title}>
              {subtitle}
            </Typography>
          </a>
        </Link>
      </div>
      <RecommendedEpisodePlayer
        isDark={isDark}
        url={audioURL}
        duration={duration}
        longVersionDuration={longVersionDuration}
        publishedAt={publishedAt}
        bookmarked={bookmarked}
        metaData={mediaMetaData}
        matureContent={podcast.matureContent}
        time={episodeTime}
      />
    </div>
  );
};
export default RecommendedPodcastTile;
