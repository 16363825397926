import Link from "next/link";
import Image from "next/image";
import classnames from "classnames";

import BookmarkButton from "@/components/BookmarkButton";
import Tag from "@/components/Tag";
import Typography from "@/components/Typography";
import { ContentSourceEnum } from "@/components/BookmarkButton/BookmarkButton.interface";
import Clock from "@/icons/clock.svg";
import { applicationRoutes } from "@/helpers/applicationRouting";
import { ArticleTileProps } from "@/helpers/pageHelpers/Article/interface";
import { formatDate } from "@/helpers/formatDate";
import { getImgixURL } from "@/helpers/getImgixURL";
import { getReadingTime } from "@/helpers/formatDuration";

import styles from "./ArticleTile.module.scss";

export const ArticleTile: React.FC<ArticleTileProps> = ({
  id,
  title,
  slug,
  publishedAt,
  readingTime,
  image,
  tags,
  author,
  bookmarked,
  className,
  from,
  index,
  sneakPeak,
  uiType,
  variant,
}) => {
  const img = getImgixURL(image);
  return (
    <div
      key={id}
      className={classnames(styles.tile, styles[variant], className)}
    >
      <Link
        href={{
          pathname: `${applicationRoutes.ARTICLE}[slug]`,
          query: { from },
        }}
        as={applicationRoutes.ARTICLE + slug}
      >
        <a className={styles.image}>
          {img && (
            <Image
              layout="fill"
              objectFit="cover"
              src={img}
              placeholder="blur"
              blurDataURL={`https://newonce.imgix.net/${img}?fm=blurhash`}
              priority={index < 2}
              alt={title}
              className={styles.img}
            />
          )}
        </a>
      </Link>
      <div className={styles.meta}>
        <Link
          href={{
            pathname: `${applicationRoutes.ARTICLE}[slug]`,
            query: { from },
          }}
          as={applicationRoutes.ARTICLE + slug}
        >
          <a className={styles.link}>
            <Typography variant="h4" component="h3" className={styles.title}>
              {title}
            </Typography>
          </a>
        </Link>
        <div className={styles.description}>
          <Typography
            variant="medium"
            component="p"
            className={styles.sneakPeak}
          >
            {sneakPeak}
          </Typography>
          <div className={styles.time}>
            {publishedAt && (
              <Typography variant="small" component="time">
                {formatDate(publishedAt)}
              </Typography>
            )}

            {readingTime && (
              <div className={styles.readingTime}>
                <span>•</span>
                <Clock />
                <Typography variant="small" component="span">
                  {getReadingTime(readingTime)}
                </Typography>
              </div>
            )}
          </div>
        </div>

        <Link
          href={{
            pathname: `${applicationRoutes.AUTHOR}[slug]`,
            query: { from: "Feed" },
          }}
          as={applicationRoutes.AUTHOR + author.slug}
          key={author?.id}
        >
          <a className={classnames(styles.link, styles.author)}>
            {author?.name}
          </a>
        </Link>

        {tags && (
          <div className={styles.tags}>
            {tags.slice(0, 2).map((tag) => (
              <Link
                key={tag.title}
                href={`${applicationRoutes.TAG}[slug]`}
                as={applicationRoutes.TAG + tag.slug}
              >
                <a className={classnames(styles.link, styles.articleTag)}>
                  <Tag {...tag}>{tag.title}</Tag>
                </a>
              </Link>
            ))}
          </div>
        )}
      </div>
      <BookmarkButton
        size="medium"
        variant="round"
        slug={slug}
        source={ContentSourceEnum.Articles}
        bookmarked={bookmarked}
        className={styles.bookmark}
        uiType={uiType}
      />
    </div>
  );
};

export default ArticleTile;
