import React, { useState } from "react";
import classnames from "classnames";
import { useStoreState } from "pullstate";
import { parseCookies } from "nookies";

import PauseIcon from "@/icons/pause.svg";
import PlayIcon from "@/icons/play.svg";
import MicrophoneIcon from "@/icons/micophone.svg";
import { PlayerStore, psPauseAudio, psPlaySource } from "@/store/PlayerStore";
import { formatDate } from "@/helpers/formatDate";
import { formatDuration } from "@/helpers/formatDuration";
import { PlayerStatusTypes } from "@/helpers/pageHelpers/Common/interface";
import IconButton from "@/components/IconButton";
import BookmarkButton from "@/components/BookmarkButton";
import ProgressBar from "@/components/Players/ProgressBar";
import Spinner from "@/components/Spinner";
import { ContentSourceEnum } from "@/components/BookmarkButton/BookmarkButton.interface";
import MinimalTimeline from "@/components/Players/MinimalTimeline";
import MatureArticleModal from "@/components/Modals/MatureArticleModal";

import { EpisodePlayerInterface } from "./EpisodePlayer.interface";
import styles from "./RecommendedEpisodePlayer.module.scss";

export const RecommendedEpisodePlayer: React.FC<EpisodePlayerInterface> = ({
  metaData,
  variant = "light",
  url,
  duration,
  longVersionDuration,
  publishedAt,
  isDark,
  className,
  matureContent,
  time,
}) => {
  const [isMatureModalOpen, setMatureModal] = useState<boolean>(false);
  const {
    audioCurrentTime: currentTime,
    currentSource,
    isLoading,
    status,
  } = useStoreState(PlayerStore, (store) => ({
    audioCurrentTime: store.audioCurrentTime,
    currentSource: store.currentSource,
    isLoading:
      store.playerStatus === PlayerStatusTypes.Loading &&
      store.currentSource === url,
    status: store.playerStatus,
    slug: store.slug,
  }));
  const isActivePlayer = currentSource === url;
  const isPlaying = isActivePlayer && status === PlayerStatusTypes.Playing;
  const cookies = parseCookies();
  const cookieExist = cookies?.ageConfirmation !== undefined;
  const defaultTime = time | 0;
  const longDuration = longVersionDuration ? longVersionDuration : duration;

  const play = () => {
    PlayerStore.update(psPlaySource(url, metaData, time));
  };
  const stop = () => {
    PlayerStore.update(psPauseAudio);
  };
  const handlePlayButton = () => {
    if (!cookieExist && matureContent) {
      setMatureModal(true);
      return;
    } else {
      play();
    }
  };
  const closeMatureModal = () => {
    setMatureModal(false);
    // PlayerStore.update(psPlaySource(url, metaData));
  };
  return (
    <>
      <div
        className={classnames(
          styles.player,
          styles[variant],
          isDark && styles.dark,
          className
        )}
      >
        <div className={styles.podcastPlayer}>
          <div className={styles.playerControls}>
            <div className={styles.playerInfo}>
              {publishedAt && !isPlaying && !isLoading && (
                <time className={styles.uploadTime} dateTime={publishedAt}>
                  {formatDate(publishedAt)}
                </time>
              )}
              {(isPlaying || isLoading) && formatDuration(currentTime)}
              <span className={styles.duration}>
                {isPlaying || isActivePlayer
                  ? formatDuration(longDuration - currentTime)
                  : formatDuration(longDuration)}
                <MicrophoneIcon />
              </span>
              <MinimalTimeline
                isWhite={isDark}
                isActive={isPlaying}
                isLoading={isLoading}
                duration={longDuration}
              />
            </div>
            <ProgressBar
              isActivePlayer={isPlaying}
              isDark={isDark}
              duration={longDuration}
              currentTime={
                isPlaying || isActivePlayer ? currentTime : defaultTime
              }
            />
          </div>
          <IconButton
            onClick={isPlaying ? stop : handlePlayButton}
            className={styles.playButton}
            variant={isDark ? "dark" : "primary"}
          >
            {isLoading ? <Spinner /> : isPlaying ? <PauseIcon /> : <PlayIcon />}
          </IconButton>
          <BookmarkButton
            size="big"
            variant={isDark ? "dark" : "primary"}
            slug={metaData.slug}
            source={ContentSourceEnum.Episodes}
            className={styles.bookmarkButton}
          />
          <audio
            src={url}
            className={styles.audio}
            preload="none"
            suppressHydrationWarning
          >
            <source src={url} type="audio/mpeg" suppressHydrationWarning />
          </audio>
        </div>
      </div>
      {!cookieExist && matureContent && (
        <MatureArticleModal
          onClose={closeMatureModal}
          isOpen={isMatureModalOpen}
          callback={play}
        />
      )}
    </>
  );
};
export default RecommendedEpisodePlayer;
