import React from "react";
import { useRouter } from "next/router";
import classnames from "classnames";

import Container from "@/components/Container";
import { GridColumns } from "@/components/Container/Container.interface";
import useRequest from "@/helpers/useRequest";
import { apiPath } from "@/helpers/apiPath";
import {
  RecommendedContentInterface,
  RecommendedInterface,
} from "@/components/Recommended/Recommended.interface";
import ArticleTile from "@/components/FeedTiles/ArticleTile";
import RecommendedPodcastTile from "@/components/FeedTiles/PodcastTile/RecommendedPodcastTile";
import { ArticleTileProps } from "@/helpers/pageHelpers/Article/interface";
import { PodcastTileInterface } from "@/helpers/pageHelpers/Podcast/interface";
import { RecommendedTypes } from "@/helpers/pageHelpers/Common/interface";
import { routePath } from "@/helpers/routePath";

import RecommendedWrapper from "./RecommendedWrapper";
import styles from "./Recommended.module.scss";

export const Recommended: React.FC<RecommendedInterface> = ({
  data: initialData,
  quantity,
  variant,
  title = "Polecane",
}) => {
  const router = useRouter();
  const { data } = useRequest(apiPath.RECOMMENDED, {
    initialData,
  }) as RecommendedContentInterface;

  const getFrom = () => {
    switch (router.pathname) {
      case routePath.ARTICLE.href:
        return "Artykuł";
      case routePath.PODCAST_EPISODE.href:
        return "Epizod";
      case routePath.PODCAST.href:
        return "Podcast";
      case routePath.AUTHOR.href:
        return "Redaktor";
      case routePath.AUTHORS.href:
        return "Redakcja";
      default:
        return "Feed";
    }
  };

  return (
    <RecommendedWrapper title={title}>
      <Container
        grid={GridColumns.Three}
        className={classnames(styles.carousel, styles[variant])}
      >
        {data
          ?.slice(0, quantity || data?.length)
          .map(({ type, object }, index) => {
            switch (type) {
              case RecommendedTypes.Article:
                return (
                  <ArticleTile
                    key={index}
                    variant="small"
                    className={styles.item}
                    from={getFrom()}
                    {...(object as ArticleTileProps)}
                  />
                );
              case RecommendedTypes.Episode:
                return (
                  <RecommendedPodcastTile
                    key={index}
                    size="medium"
                    className={styles.item}
                    {...(object as PodcastTileInterface)}
                  />
                );
              default:
                return null;
            }
          })}
      </Container>
    </RecommendedWrapper>
  );
};

export default Recommended;
