import React from "react";
import { getTrackBackground, Range } from "react-range";
import classnames from "classnames";
import { useStoreState } from "pullstate";

import { PlayerStore, psChangeTime, psPlayAudio } from "@/store/PlayerStore";

import styles from "../EpisodePlayer/EpisodePlayer.module.scss";

import { MinimalTimelineInterface } from "./MinimalTimeline.interface";

export const MinimalTimeline: React.FC<MinimalTimelineInterface> = ({
  isWhite,
  onEpisodePage = false,
  isActive,
  isLoading,
  duration,
}) => {
  const { audioCurrentTime: currentTime } = useStoreState(
    PlayerStore,
    (store) => store
  );
  const progressTrackColor = isWhite
    ? "var(--core-white)"
    : "var(--content-primary)";

  const finalChange = () => {
    PlayerStore.update(psPlayAudio);
  };

  const changeTime = (value) => {
    PlayerStore.update(psChangeTime(value[0]));
  };

  return (
    <div
      className={classnames(
        styles.mixerControls,
        onEpisodePage && styles.pushTop
      )}
    >
      <Range
        values={[
          currentTime > duration ? 1 : currentTime < 0 ? 0 : currentTime,
        ]}
        step={1}
        min={0}
        max={duration === 0 ? 1 : duration}
        onFinalChange={finalChange}
        onChange={changeTime}
        renderTrack={({ props, children }) => (
          <div
            className={classnames(
              styles.mixerTrack,
              (isActive || isLoading) && styles.playing
            )}
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{ ...props.style }}
          >
            <div
              className={styles.mixerProgress}
              ref={props.ref}
              style={{
                background: getTrackBackground({
                  values: [currentTime > duration ? 1 : currentTime],
                  colors: [progressTrackColor, "transparent"],
                  min: 0,
                  max: duration === 0 ? 1 : duration,
                }),
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            style={{ ...props.style }}
            className={classnames(styles.mixerThumb, isWhite && styles.white)}
            {...props}
          />
        )}
      />
    </div>
  );
};

export default MinimalTimeline;
