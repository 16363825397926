import classnames from "classnames";

import Container from "@/components/Container";
import Typography from "@/components/Typography";

import { RecommendedWrapperInterface } from "./Recommended.interface";
import styles from "./Recommended.module.scss";

const RecommendedWrapper: React.FC<RecommendedWrapperInterface> = ({
  title = "Polecane",
  className,
  children,
}) => (
  <div className={styles.recommended}>
    <Container>
      <Typography
        className={classnames(styles.heading, className)}
        component="h5"
      >
        {title}
      </Typography>
    </Container>
    {children}
  </div>
);
export default RecommendedWrapper;
