import React from "react";
import classnames from "classnames";

import { TagProps } from "./Tag.interface";
import styles from "./Tag.module.scss";

export const Tag: React.FC<TagProps> = ({ title, className, children }) => {
  const portal = process.env.NEXT_PUBLIC_PORTAL;

  return (
    <span
      className={classnames(styles.tag, styles[portal], className && className)}
      title={title}
    >
      {children}
    </span>
  );
};

export default Tag;
